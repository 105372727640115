/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as CONSTANTS from './constants';

const DOMAIN = CONSTANTS.WEB_URL;

const { WORKFLOW_BASE_URL } = CONSTANTS;

const { BUILD_VERSION } = process.env;

const myListUrls = {
  list: `${DOMAIN}/mylist.htm`,
  listUpdate: `${DOMAIN}/mylist/<<id>>.htm`,
  getListContacts: `${DOMAIN}/mylist/<<id>>/contact.htm?limit=<::limit>&pagination=<::pagination>&lastOwnedId=<::lastOwnedId>&lastOwnedIdUpdateTs=<::lastOwnedIdUpdateTs>&isSharedList=<::isSharedList>&teamId=<::teamId>`,
  listContacts: `${DOMAIN}/mylist/<<id>>/contact.htm`,
};

const purchaseUrls = {
  sync: `${DOMAIN}/purchase/contact/sync.htm`,
  async: `${DOMAIN}/purchase/contact/async.htm`,
};

const workflowUrls = {
  createWorkflow: `${WORKFLOW_BASE_URL}/wf/api/workflow/create`,
  listWorkflows: `${WORKFLOW_BASE_URL}/wf/api/workflow/list`,
  listTemplates: `${WORKFLOW_BASE_URL}/wf/api/template/list`,
  getTemplatePreviewById: `${WORKFLOW_BASE_URL}/wf/api/template/<<templateId>>`,
  getVendorList: `${WORKFLOW_BASE_URL}/wf/api/vendor/list/<<stageId>>`,
  editWorkflow: `${WORKFLOW_BASE_URL}/wf/api/workflow/edit`,
  getWorkflowById: `${WORKFLOW_BASE_URL}/wf/api/workflow/<<id>>`,
  getVendorInputConfigurations: `${WORKFLOW_BASE_URL}/wf/api/vendor/action/<<vendorActionId>>`,
  saveWorkflowStage: `${WORKFLOW_BASE_URL}/wf/api/workflow/save`,
  getVendorAuthStatus: `${WORKFLOW_BASE_URL}/wf/api/vendor/auth/status?memberId=<::memberId>`,
  saveVendorAuthStatus: `${WORKFLOW_BASE_URL}/wf/api/vendor/auth/connect`,
  deleteVendorAuthStatus: `${WORKFLOW_BASE_URL}/wf/api/vendor/auth/disconnect`,
  dynamicOptions: `${WORKFLOW_BASE_URL}/wf/api/dynamic/`,
  getEnrollmentHistory: `${WORKFLOW_BASE_URL}/wf/api/enrollment/list?workflowId=<::workflowId>&page=<::page>&size=<::size>&sortBy=<::sortBy>&sortDirection=<::sortDirection>`,
  updateWorkflowStatusById: `${WORKFLOW_BASE_URL}/wf/api/workflow/<<id>>/status`,
  runWorkflow: `${WORKFLOW_BASE_URL}/wf/api/workflow/run`,
  stopWorkflow: `${WORKFLOW_BASE_URL}/wf/api/workflow/stop`,
  getVendorByIds: `${WORKFLOW_BASE_URL}/wf/api/vendor/list?ids=<::ids>`,
  getEnrollmentDetailAPILog: `${WORKFLOW_BASE_URL}/wf/api/log/?enrollmentDetailsId=<::enrollmentDetailsId>&workflowId=<::workflowId>&stageId=<::stageId>`,
};

export const URLS = {
  loginValidation: `${DOMAIN}/user/details.htm`,
  logout: `${DOMAIN}/j_spring_security_logout`,
  resendEmailConfirmation: `${DOMAIN}/verifyUserEmail.htm`,
  companyNameWithDomain: `${DOMAIN}/autocomplete/companyNameWithDomain.htm?is_eu=`,
  companyNameWithDomainV2: `${DOMAIN}/autocomplete/companyNameWithDomainV2.htm`,
  companyKeyword: `${DOMAIN}/autocomplete/companyKeyword.htm`,
  technologies: `${DOMAIN}/autocomplete/technologies.htm`,
  getIndustrySuggestions: `${DOMAIN}/getIndustrySuggestions.htm?searchText=`,
  getDepartment: `${DOMAIN}/advanced/allDepartment.htm`,
  getLevel: `${DOMAIN}/advanced/allLevel.htm`,
  getLocation: `${DOMAIN}/autocomplete/location.htm`,
  searchResults: `${DOMAIN}/advanced/searchResults.htm`,
  companySearchResults: `${DOMAIN}/company-search/searchResults.htm`,
  checkEuCountry: `${DOMAIN}/checkForEU.htm`,
  updatePassword: `${DOMAIN}/updatePassword.htm?`,
  checkCurrentPassword: `${DOMAIN}/checkCurrentPassword.htm?`,
  updateMemberTimeZone: `${DOMAIN}/updateMemberTimeZone.htm`,
  getSavedCard: `${DOMAIN}/getSavedCard.htm`,
  sendStripeTokenToServer: `${DOMAIN}/updateStripe.htm`,
  team: `${DOMAIN}/team/member.htm`,
  exportSelectedLeadsToZoho: `${DOMAIN}/exportSelectedLeadsToZoho.htm`,
  pipeDriveToken: `${DOMAIN}/auth/pipedrive/token.htm`,
  exportLeadsToPipedrive: `${DOMAIN}/exportLeadsToPipedrive.htm`,
  exportLeadsToOutReach: `${DOMAIN}/exportLeadsToOutReach.htm`,
  exportLeadsOutreachSequence: `${DOMAIN}/outreach/sequences.htm`,
  exportLeadsToMarketo: `${DOMAIN}/exportLeadsToMarketo.htm`,
  reportMarkAsInactive: `${DOMAIN}/reportAsInactiveMasterContact.htm`,
  getValidDomains: `${DOMAIN}/getValidDomains.htm`,
  getHubspotCalendarLink: `${DOMAIN}/getAccountOwnerCalendarLink.htm`,
  trialUser: {
    upgradeNow: `${DOMAIN}/requestUpgradeByTrialUser.htm`,
    updatePopupActivity: `${DOMAIN}/updatePopupActivity.htm`,
    isTeamMemberInvited: `${DOMAIN}/isTeamMemberInvited.htm`,
  },
  verifyTrialRequest: `${DOMAIN}/verifyTrialRequest.htm`,
  updatePaidUserWelcomePopupShown: `${DOMAIN}/updateWelcomePopupShown.htm`,
  hubspotEventPush: `${DOMAIN}/hubspotEventPush.htm`,
  getCompanyInfoById: `${DOMAIN}/getCompanyInfoById.htm`,
  getIndustrySectorsSuggestion: `${DOMAIN}/autocomplete/industrysectors.htm`,
  suppressionList: {
    getList: `${DOMAIN}/inclusionExclusionList/getLists.htm?type=`,
    getValidEntries: `${DOMAIN}/inclusionExclusionList/getValidEntries.htm`,
    saveList: `${DOMAIN}/inclusionExclusionList/saveList.htm`,
    enableDisableList: `${DOMAIN}/inclusionExclusionList/enableDisableList.htm`,
    deleteList: `${DOMAIN}/inclusionExclusionList/deleteList.htm`,
    downloadList: `${DOMAIN}/inclusionExclusionList/download.htm`,
  },
  technographicDemoRequest: `${DOMAIN}/technographicDemoRequest.htm`,
  companyExport: {
    exportSelectedCompaniesToCSV: `${DOMAIN}/exportSelectedCompaniesToCSV.htm`,
    exportBulkCompaniesToCSV: `${DOMAIN}/exportCompaniesToCSV.htm`,
  },
  salesgear: {
    authenticate: `${DOMAIN}/authenticateSalesgear.htm`,
    getUserDetail: `${DOMAIN}/getAuthenticatedSalesgearMember.htm`,
    validateMandatoryFields: `${DOMAIN}/export/salesgear/validate.htm`,
    exportToContact: `${DOMAIN}/export/salesgear.htm`,
    getAllSequence: `${DOMAIN}/seq/getAllSequence.htm`,
    getAllSequenceFolders: `${DOMAIN}/seq/getFolders.htm`,
    getFolderSeqeunceById: `${DOMAIN}/seq/getSequenceByFolder/<<folderId>>.htm`,
    getSharedSequence: `${DOMAIN}/seq/getSharedSequence.htm`,
  },
  crmIntegrations: {
    getAllStatus: `${DOMAIN}/crm/getAllStatus.htm`,
    connect: `${DOMAIN}/crm/connect.htm?crmType=<::crmType>`,
    disconnect: `${DOMAIN}/crm/disconnect.htm?crmType=<::crmType>`,
    getStatus: `${DOMAIN}/crm/getStatus.htm?crmType=<::crmType>`,
  },
  crmFieldMapping: `${DOMAIN}/crm/fieldMapping.htm?crmType=<::crmType>&exportType=<::exportType>&crmSettingsId=<::crmSettingsId>`,
  enrichmentSettings: `${DOMAIN}/crm/enrichmentConfig.htm?crmType=<::crmType>&crmSettingsId=<::crmSettingsId>`,
  crmExport: {
    export: `${DOMAIN}/crm/export.htm?crmType=<::crmType>&type=<::type>&source=<::source>&exportType=<::exportType>`,
    accountOwnershipMapping: `${DOMAIN}/crm/accountOwnershipMapping.htm?crmType=<::crmType>&type=<::type>&source=<::source>`,
  },
  userAnalytics: {
    emailReportForRecurring: `${DOMAIN}/usageanalytical/schedule.htm`,
    deleteRecurringMails: `${DOMAIN}/usageanalytical/<<mailId>>/schedule.htm`,
  },
  getMemberInfoUsingEmail: `${DOMAIN}/getMemberInfoUsingEmail.htm`,
  verifyLBTrialRequest: `${DOMAIN}/verifyLBTrialRequest.htm`,
  updateProfileData: `${DOMAIN}/updateProfileData.htm`,
  getEnrichmentLogs: `${DOMAIN}/crm/enrichmentLog.htm?crmType=<::crmType>&crmSettingsId=<::crmSettingsId>&from=<::from>&to=<::to>&page=<::page>&count=<::count>`,
  getEnrichmentLogsCount: `${DOMAIN}/crm/enrichmentLogCount.htm?crmType=<::crmType>&from=<::from>&to=<::to>&crmSettingsId=<::crmSettingsId>`,
  downloadEnrichmentLogs: `${DOMAIN}/crm/downloadEnrichmentLog.htm?crmType=<::crmType>&from=<::from>&to=<::to>&crmSettingsId=<::crmSettingsId>`,
  fetchCredits: `${DOMAIN}/fetchCredits.htm`,
  getPriceAmount: `${DOMAIN}/getPriceAmount.htm?credits=<::credits>`,
  getUsageAnalytics: `${DOMAIN}/getUsageAnalytics.htm`,
  updateMemberEmail: `${DOMAIN}/updateMemberEmail.htm`,
  captureUserInterestInAdapt: `${DOMAIN}/captureUserInterestInAdapt.htm`,
  setUserPassword: `${DOMAIN}/setUserPassword.htm`,
  notifyEnterprisePlanInterest: `${DOMAIN}/notifyEnterprisePlanInterest.htm?notificationType=<::notificationType>`,
  savedSearchV2: `${DOMAIN}/savedsearches/search.htm?searchType=<::searchType>&limit=<::limit>&lastId=<::lastId>`,
  deleteSavedSearchV2: `${DOMAIN}/savedsearches/<<id>>/search.htm`,
  fileEnrichment: {
    getEntries: `${DOMAIN}/fileenrich/entry.htm?page=<::page>&limit=<::limit>`,
    uploadCSV: `${DOMAIN}/fileenrich/uploadandcreate.htm`,
    updateFieldMapping: `${DOMAIN}/fileenrich/fieldmapping.htm`,
  },
  updateEnrichmentSteps: `${DOMAIN}/updateEnrichmentSteps.htm`,
  saveEmailSearchPref: `${DOMAIN}/saveSearchCriteriaFlags.htm`,
  apiUsageAnalytics: `${DOMAIN}/usage/analytics/api.htm?startDate=<::startDate>&endDate=<::endDate>&frequency=<::frequency>`,
  notifyMobileNumberInterest: `${DOMAIN}/notifyMobileNumberInterest.htm`,
  exportToCSVV2: `${DOMAIN}/exportToCSV.htm`,
  myList: myListUrls,
  purchase: purchaseUrls,
  SFAuth: `${DOMAIN}/crm/salesforce/auth/status.htm`,
  getAllRegions: `${DOMAIN}/advanced/allRegion.htm`,
  customerExecutiveList: `${DOMAIN}/other/customer-executive-info.htm`,
  paKeywords: `${DOMAIN}/advanced/allPaKeywords.htm`,
  fundingData: `${DOMAIN}/advanced/allFundingTypes.htm`,
  getSubscriptionPlans: `${DOMAIN}/subscription/plans.htm`,
  purchasePlan: `${DOMAIN}/subscription/purchase.htm`,
  getBulkPurchaseList: `${DOMAIN}/purchase/list.htm?page=<::page>&size=<::size>&purchaseIds=<::purchaseIds>`,
  workflow: workflowUrls,
  getMembersName: `${DOMAIN}/getMembersName.htm?teamId=<::teamId>`,
  updateUserActions: `${DOMAIN}/user/actions.htm`,
};

export const makeApi = (
  config,
  noCache = true,
  doCancelRequest,
  withCredentials = true,
  options = {},
) => {
  const { CancelToken } = axios;
  const { sendContentType = true } = options;
  config.method = config.method || 'GET';
  config.headers = {
    ...(config.headers ?? {}),
    Accept: 'application/json',
    ...(sendContentType && { 'Content-Type': 'application/json' }),
    'X-Ui-Version':
      process.env.BUILD_ENV === 'local' ? 'default' : BUILD_VERSION,
  };

  if (noCache) {
    config.headers['Cache-Control'] = 'no-cache';
  }

  config.withCredentials = withCredentials;
  if (doCancelRequest !== undefined) {
    config.cancelToken = new CancelToken((c) => {
      doCancelRequest(c);
    });
  }

  return axios(config)
    .then((response) => {
      const UIBuildLatest = response.headers?.['x-ui-latest'];
      if (UIBuildLatest && BUILD_VERSION && process.env.BUILD_ENV !== 'local') {
        const parsedCurrentVersion = parseInt(BUILD_VERSION);
        const parsedLatestVersion = parseInt(UIBuildLatest);
        if (parsedCurrentVersion < parsedLatestVersion) {
          window.location.reload();
        }
      }
      return response;
    })
    .catch((response) => {
      if (
        (response.config !== undefined &&
          response.config.url !== undefined &&
          response.config.url.includes('j_spring_security_logout')) ||
        (response &&
          response.response !== undefined &&
          response.response.status === 401)
      ) {
        document.cookie = 'li=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = CONSTANTS.WEB_URL + CONSTANTS.BASE_LOGIN_URL;
      }

      return response;
    });
};

export default makeApi;
