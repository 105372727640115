/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import { makeApi, URLS } from 'Utils/apiUtil';
import { action, computed, observable } from 'mobx';

const IGNORED_KEYWORDS = [
  '3',
  '4',
  '5',
  '7',
  '14',
  '24',
  '25',
  '28',
  '30',
  '31',
  '41',
  '42',
  '44',
  '60',
  '61',
  '62',
  '70',
  '75',
  '76',
  '79',
  '85',
  '109',
  '113',
  '130',
  '131',
  '145',
  '146',
  '148',
  '149',
  '153',
  '154',
  '160',
  '169',
  '171',
  '172',
  '178',
  '182',
  '184',
  '189',
  '198',
  '204',
  '207',
  '210',
  '211',
  '213',
  '227',
  '239',
  '242',
  '243',
  '246',
  '250',
  '251',
];

class AdvSearchFilters {
  @observable accessor filterListValues = {
    level: [],
    department: [],
    regions: [],
    paKeywords: [],
    funding: [],
  };

  flatMapCallback = (data) => {
    const { subList = [], ...remainingProps } = data;
    return [{ ...remainingProps }, ...subList];
  };

  @computed
  get flatListKeywords() {
    return this.filterListValues.paKeywords.flatMap(this.flatMapCallback);
  }

  @computed
  get flatListFunding() {
    return this.filterListValues.funding.flatMap(this.flatMapCallback);
  }

  async getFilterValues(url, generateListFunction) {
    const config = {
      url,
      method: 'GET',
    };
    const response = await makeApi(config);

    const list =
      typeof generateListFunction === 'function'
        ? generateListFunction(response.data)
        : this.generateListForDropdown(response.data);

    return list;
  }

  generateListForDropdown = (list) => {
    const newList =
      list?.map((item) => {
        const newObj = {
          selected: false,
        };
        newObj.name = item;
        newObj.pName = item;
        return newObj;
      }) ?? [];

    return newList;
  };

  @action
  getLevel = async () => {
    const values = await this.getFilterValues(URLS.getLevel);
    this.filterListValues = {
      ...this.filterListValues,
      level: values,
    };
  };

  @action
  getDepartment = async () => {
    const values = await this.getFilterValues(URLS.getDepartment);
    this.filterListValues = {
      ...this.filterListValues,
      department: values,
    };
  };

  fetchFilterValues = (getIndustryCodes = true) => {
    if (this.filterListValues.level.length === 0) {
      this.getLevel();
    }
    if (this.filterListValues.department.length === 0) {
      this.getDepartment();
    }
    if (this.filterListValues.regions.length === 0) {
      this.getRegions();
    }
  };

  getFundingAndTechData = () => {
    if (this.filterListValues.paKeywords.length === 0) {
      this.getPAKeywords();
    }
    if (this.filterListValues.funding.length === 0) {
      this.getFundingData();
    }
  };

  getCompanyNameSuggestions = async (value) => {
    const config = {
      url: URLS.companyNameWithDomainV2,
      method: 'POST',
      data: {
        q: value,
      },
    };
    const response = await makeApi(config);
    if (response.data?.length) {
      return response.data
        .map((prop) => {
          const { name, domain } = prop;
          return { text: name, description: domain };
        })
        .sort((value1, value2) => {
          if (value1.description?.toLowerCase() === value?.toLowerCase()) {
            return -1;
          }
          if (value2.description?.toLowerCase() === value?.toLowerCase()) {
            return 1;
          }
          return 0;
        });
    }
    return [];
  };

  getTechnologiesSuggestions = async (queryString = '') => {
    const config = {
      url: URLS.technologies,
      method: 'POST',
      data: {
        searchText: queryString,
        cursorMark: '',
      },
    };

    try {
      const response = await makeApi(config);
      return response.data?.suggestions?.map((prop) => {
        const { id } = prop;
        return { text: id };
      });
    } catch (error) {
      return [];
    }
  };

  getKeywordSuggestion = async (searchValue = '') => {
    const config = {
      url: URLS.companyKeyword,
      method: 'POST',
      data: {
        q: searchValue,
      },
    };
    const response = await makeApi(config);
    if (response.data?.length) {
      return response.data.map((string) => {
        return { text: string };
      });
    }
    return [];
  };

  getLocationSuggestion = async (group, searchValue = '') => {
    const config = {
      url: URLS.getLocation,
      method: 'POST',
      data: {
        q: searchValue,
        group,
      },
    };
    const response = await makeApi(config);
    if (response.data?.length) {
      return response.data.map((prop) => {
        const { name } = prop;
        return { text: name, originalData: prop };
      });
    }
    return [];
  };

  validateDomains = async (domains) => {
    const config = {
      url: URLS.getValidDomains,
      method: 'POST',
      data: { domains },
    };

    const response = await makeApi(config);
    return response?.data;
  };

  getRegions = async () => {
    const config = {
      url: URLS.getAllRegions,
      method: 'GET',
    };

    const response = await makeApi(config);
    this.filterListValues.regions = Array.isArray(response?.data)
      ? response.data.map((prop) => {
          return { label: prop.name, originalData: prop };
        })
      : [];
  };

  getPAKeywords = async () => {
    const config = {
      url: URLS.paKeywords,
      method: 'GET',
    };

    const response = await makeApi(config);

    this.filterListValues.paKeywords =
      response?.data?.length > 0
        ? response.data
            ?.filter((prop) => {
              return !IGNORED_KEYWORDS.includes(prop.code);
            })
            ?.sort((value1, value2) => {
              if (value1.name < value2.name) {
                return -1;
              }
              if (value1.name > value2.name) {
                return 1;
              }
              return 0;
            })
            ?.map((data) => {
              const { code, name, feeds } = data;
              return {
                label: name.replace(/trending themes in /i, ''),
                value: code,
                subList: feeds.map((subData) => {
                  const { code: value, name: label } = subData;
                  return { label, value };
                }),
              };
            })
        : [];
  };

  getFundingData = async () => {
    const config = {
      url: URLS.fundingData,
      method: 'GET',
    };

    const response = await makeApi(config);

    // to debug what error Sentry is throwing
    try {
      this.filterListValues.funding =
        response?.data?.map((prop) => {
          const { name, code, item } = prop;
          return {
            label: name,
            value: code,
            subList: item?.map((itemData) => {
              const { name: label, code: value } = itemData;
              return { label, value };
            }),
          };
        }) ?? [];
    } catch (e) {
      console.error(e, response?.data);
    }
  };

  getTextForValues = (list, values) => {
    if (values?.length > 0) {
      return values?.reduce((output, keyword) => {
        let text = '';
        for (let i = 0; i < list.length; i++) {
          const currentItem = list[i];
          if (currentItem?.value === keyword) {
            text = currentItem.label;
            break;
          } else if (currentItem.subList?.length > 0) {
            for (let j = 0; j < currentItem.subList.length; j++) {
              const subItem = currentItem.subList[j];
              if (subItem?.value === keyword) {
                text = subItem.label;
                break;
              }
            }
          }
        }
        return text ? [...output, text] : output;
      }, []);
    }
    return [];
  };
}

const advSearchFiltersState = new AdvSearchFilters();

export default advSearchFiltersState;
